<template>
  <navbar v-model="showNavbar">

    <template slot="navbar-menu">

      <div id="overall-bar" style="display: flex; flex-direction: row; justify-content: space-between; width: 100%;">
        <a href="/">
          <img class="logo" src="/img/icons/acuris-plain.svg" alt="Acuris Systems Logo">
        </a>

        <div class="select-container">

          <div>
            <fg-input>
              <el-select class="select-primary" size="large" placeholder="Orchard Unknown" v-model="$root.orchard" value-key="id">
                  <el-option v-for="option in $root.orchards" class="select-danger" :value="option"
                  :label="`${option.kpin} ${option.name}`" :key="option.id">
                    <div> <span style="font-weight: bold; min-width: 2.7em; display: inline-block">{{option.kpin}}</span> <span>{{option.name}}</span></div>
                </el-option>
              </el-select>
            </fg-input>
          </div>

          <div class="select-right">
            <fg-input>
              <el-select class="select-primary" size="large" placeholder="Scan Unselected" v-model="$root.scan" value-key="id">
                <el-option class="select-danger" :value="null" label="None"><span>None</span></el-option>
                <el-option v-for="option in $root.scans" class="select-danger" :value="option" :label="option.name" :key="option.id">
                  <div style="display: flex; justify-content: space-between;">
                    <span>{{option.simpleName}} <span :key="tag" style="font-size: small" v-for="tag in option.tags">#{{tag}}</span></span>
                    <span style="font-size: 0.9em; padding-left: 10px; font-style: italic" v-if="option.date_to === option.date_from">{{option.date_to}}</span>
                    <div style="font-size: 0.8em; padding-left: 10px; font-style: italic; line-height: 1.5em" v-else>{{option.date_from}}<br/>{{option.date_to}}</div>
                  </div>
                </el-option>
              </el-select>
            </fg-input>
          </div>

          <div style="margin-top: auto; margin-bottom: auto; display: flex; flex-direction: row;">
            <button :disabled="!$root.scan" class="btn btn-round btn-primary" @click="reloadScans()"><span class="el-icon-refresh"/> Reload</button>
            <drop-down class="btn-group">
              <template v-slot:title="{isOpen}" :disabled="!$root.scan">
                <!-- <button :aria-expanded="isOpen" class="dropdown-toggle btn btn-round btn-info"> -->
                <button class="dropdown-toggle btn btn-round btn-info">
                  <!-- <span class="el-icon-download"/> Download -->
                  <span class="el-icon-download"/> {{ downloadText }}
                </button>
              </template>
              <a class="dropdown-item" @click="downloadScan('csv')">CSV</a>
              <a class="dropdown-item" @click="downloadScan('xlsx')">XLSX</a>
<!--              <a class="dropdown-item" @click="downloadScan('geojson')">GeoJSON</a>-->
              <a class="dropdown-item" @click="downloadScan('pdf')">PDF</a>
            </drop-down>
          </div>

          <div class="select-right sign-out" v-if="$root.with_login" style="margin-top: auto; margin-bottom: auto">
            <button class="btn btn-round btn-warning" @click="signOut()">Sign Out</button>
          </div>

        </div>

      </div>

    </template>

  </navbar>
</template>

<script>
import { Select, Option } from 'element-ui'
import { Navbar, NavbarToggleButton, Dropdown } from 'src/components/UIComponents'
import { Auth, Hub } from 'aws-amplify';

export default {
  components: {
    Navbar,
    NavbarToggleButton,
    [Option.name]: Option,
    [Select.name]: Select,
    Dropdown
  },
  data() {
    return {
      downloadText: "Download",
      isDisabled: true,
      testStr: "hello",
      activeNotifications: false,
      showNavbar: false,
      current_icon: null,
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    async signOut() {
      try {
        await Auth.signOut();
        Hub.dispatch('UI Auth', {   // channel must be 'UI Auth'
          event: 'AuthStateChange',    // event must be 'AuthStateChange'
          message: 'signedout'    // message must be 'signedout'
        });
      } catch (error) {
        console.log('error signing out: ', error);
      }
    },
    downloadScan(format) {
      this.$root.downloadScan(format)
    },
    reloadScans() {
      this.$root.loadOrchardScans(this.$root.orchard);
    }
  }
}
</script>

<style>
.btn {
  font-size: x-small !important;
  margin: 0 0 0 8px !important;
  padding: 8px 10px !important;
}

.dropdown-menu {
  min-width: 5rem;
}

.logo {
  height: 41px;
  max-height: 41px;
}

.select-container {
  display: flex;
  flex-direction: row;
}

.select-container .button {
  border-radius: 10px;
}

.select-right {
  padding-left: 20px;
}

.select-right.sign-out {
    padding-left: 0;
  }

.select-right.button {
  border-radius: 20px;
}

.el-input input {
  font-size: 1rem;
}

@media (hover: none) {
  el-select:hover {
    color: red
  }

  el-option:hover {
    color: red
  }
}

/* .el-icon-download::after {
  display: none;
} */

.dropdown-toggle::after {
  display: none;
}

@media (max-aspect-ratio: 1/1) {
  .el-icon-download::after {
    display: none;
  }
  
  .logo {
    content: url("../../../../public/img/icons/android-chrome-192x192.png");
    margin-left: 15px;
    background: black;
    min-height: 100%;
    border-radius: 25px;
  }

  .acuris-icon-link {
    display: none;
  }

  .select-right {
    padding-left: 10px;
  }

  .select-right.sign-out {
    padding-left: 0;
  }

  .select-container {
    margin: 0 10px 0 10px;
  }

  .navbar.navbar-transparent {
    background: transparent;
    box-shadow: none;
  }
}

@media (max-height: 30em) and (orientation: landscape) {
  .navbar.navbar-transparent {
    background: transparent;
    box-shadow: none;
  }

  .logo {
    content: url("../../../../public/img/icons/android-chrome-192x192.png");
    margin-left: 15px;
    background: black;
    min-height: 100%;
    border-radius: 25px;
  }
}

</style>
